import { headerLinks } from "../../data/navigation";
import styles from "./Header.module.css";
import { useOrgObject } from "../../hooks/useOrgObject";
import OrgLink from "../OrgLink/OrgLink";
import HamburgerButton from "../Hamburger/HamburgerButton";
import HamburgerMenu from "../Hamburger/HamburgerMenu";

function Header({ viewportName, darkMode }) {
    const orgObject = useOrgObject();

    return (
        <header
            id={styles.header}
            className={[
                styles[viewportName],
                "section-content",
                darkMode ? styles.dark_mode : "",
            ].join(" ")}
        >
            {/* <SignUpModal /> */}
            <HamburgerMenu className={styles.hamburger} />
            <div id={styles.left}>
                <OrgLink>
                    <img
                        draggable={false}
                        id={styles.logo}
                        src={orgObject.logoSrc}
                        alt={orgObject.name + " logo"}
                    />
                </OrgLink>
            </div>
            <nav id={styles.middle} className="desktop">
                {headerLinks.map(({ name, href, ...props }) => (
                    <OrgLink
                        className={styles.link}
                        key={name}
                        to={href}
                        activeClassName={styles.active}
                        {...props}
                    >
                        {name}
                    </OrgLink>
                ))}
            </nav>
            <div id={styles.right}>
                <OrgLink
                    id={styles.login_link}
                    className={"link-button " + (darkMode ? "dark-mode" : "")}
                    to={orgObject.loginLink}
                    target={"_blank"}
                >
                    Access my Account
                </OrgLink>
                <HamburgerButton dark={darkMode} className={styles.hamburger} />
            </div>
        </header>
    );
}

export default Header;
