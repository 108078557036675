function useActivateMatomo() {
    // function useActivateMatomo(region) {
    const region = "ca"; // region always ca for now

    function getSiteID() {
        if (
            window.location.hostname === "localhost" ||
            window.location.hostname === "dev.peersupport.keelmind.com"
        ) {
            return "2";
        } else if (region === "ca") {
            return "4";
        } else {
            return "5";
        }
    }

    function getUrl() {
        if (region === "ca") {
            return "https://analytics.ca.keelmind.com/";
        } else {
            return "https://analytics.us.keelmind.com/";
        }
    }

    var _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    // _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
        var u = getUrl();
        _paq.push(["setTrackerUrl", u + "matomo.php"]);
        _paq.push(["setSiteId", getSiteID()]);
        var d = document,
            g = d.createElement("script"),
            s = d.getElementsByTagName("script")[0];
        g.async = true;
        g.src = u + "matomo.js";
        s.parentNode.insertBefore(g, s);
    })();
}

export default useActivateMatomo;
