import { Link } from "react-router-dom";
import styles from "./EmailOption.module.css";

function EmailOption({ name, src, href }) {
    return (
        <Link
            target="_blank"
            rel="noreferrer"
            to={href}
            className={styles.email_option}
        >
            <img src={src} alt={name + " logo"} className={styles.icon} />
            <div className={styles.text}>
                <strong className={styles.name}>{name}</strong>
                <p className={styles.description}>
                    Free encrypted email provider
                </p>
            </div>
        </Link>
    );
}

export default EmailOption;
