import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import useDynamicViewportName from "../../hooks/useDynamicViewportName";
import AnonymousOptionsDesktop from "./AnonymousOptionsDesktop/AnonymousOptionsDesktop";

function AnonymousOptions() {
    const viewportName = useDynamicViewportName();
    return (
        <div>
            <OrgThemeHelper />
            <AnonymousOptionsDesktop viewportName={viewportName} />
        </div>
    );
}

export default AnonymousOptions;
