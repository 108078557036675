import { NavLink } from "react-router-dom";
import { useOrgObject } from "../../hooks/useOrgObject";

function OrgLink({
    to = "",
    style,
    id,
    className,
    rel,
    target,
    children,
    sendPageView = false,
    pageViewTitle,
    activeClassName,
}) {
    const orgObject = useOrgObject();
    const overridePrefixes = ["mailto", "http"];
    function shouldOverride() {
        return overridePrefixes.some((prefix) => to.includes(prefix));
    }

    function getOrgHref() {
        return shouldOverride()
            ? to
            : `${orgObject.key ? `/${orgObject.key}` : ""}${
                  to[0] === "/" ? to : "/" + to
              }`;
    }

    function handleClick() {
        if (sendPageView) {
            if (window.gtag) {
                window.gtag("event", "page_view", {
                    page_path: window.location.pathname,
                    page_location: window.location.href,
                    page_title: `${pageViewTitle} | ${orgObject.name} Peer Support`,
                });
            }

            if (
                window._paq &&
                (window.location.hostname === "localhost" ||
                    window.location.hostname === "dev.peersupport.keelmind.com")
            ) {
                window._paq.push(["setCustomUrl", window.location.pathname]);
                window._paq.push([
                    "setDocumentTitle",
                    `${pageViewTitle} | ${orgObject.name} Peer Support`,
                ]);
                window._paq.push(["trackPageView"]);
            }
        }
    }

    return (
        <NavLink
            to={getOrgHref()}
            id={id}
            className={({ isActive }) =>
                `${className} ${isActive ? activeClassName || "" : ""}`
            }
            style={style}
            rel={rel}
            target={target}
            onClick={handleClick}
        >
            {children}
        </NavLink>
    );
}

export default OrgLink;
