import Header from "../../components/Header/Header";
import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import styles from "./AllOrgs.module.css";
import orgs from "../../data/orgs";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";

function AllOrgs({ viewportName = "desktop" }) {
    return (
        <main id={styles.main}>
            <ScrollToTopButton topThreshold={300} />
            <OrgThemeHelper />
            <Header darkMode={true} viewportName={viewportName} />
            <section id={styles.content_section}>
                <div
                    className="section-content"
                    id={styles.content_section_content}
                >
                    <h2>All Peer Support Organizations</h2>
                    <div className={styles.all_orgs_container}>
                        {Object.entries(orgs).map(([key, value]) => (
                            <Link
                                key={key}
                                to={"/" + key}
                                className={styles.link}
                            >
                                <div className={styles.link_text}>
                                    <div
                                        className={styles.swatch}
                                        style={{
                                            backgroundColor:
                                                value.secondaryColor,
                                        }}
                                    ></div>
                                    {value.name}
                                </div>
                                <img
                                    src={`https://assets.cdn.geta-head.com/peersupport-v2/${key}/img/${key}-logo.png`}
                                    alt={value.name + " logo"}
                                    className={styles.logo}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    );
}

export default AllOrgs;
