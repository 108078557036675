import { useLayoutEffect, useState } from "react";

const useDynamicViewportName = () => {
    const [viewportName, setViewportName] = useState({ width: 0, height: 0 });

    const handleResize = () => {
        setViewportName(window.innerWidth > 660 ? "desktop" : "mobile");
    };

    useLayoutEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return viewportName;
};

export default useDynamicViewportName;
