import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import useTrackPageView from "../../hooks/useTrackPageView";
import { useOrgObject } from "../../hooks/useOrgObject";
import usePageTitle from "../../hooks/usePageTitle";
import PeerConnectDesktop from "./PeerConnectDesktop/PeerConnectDesktop";
import PeerConnectMobile from "./PeerConnectMobile/PeerConnectMobile";
import Home from "../Home/Home";

function PeerConnect() {
    const { name, removePillars } = useOrgObject();
    usePageTitle(`Explore Peer Supporters | ${name} Peer Support`);
    useTrackPageView();

    return removePillars.keelReels ? (
        <Home />
    ) : (
        <div>
            <OrgThemeHelper />
            <PeerConnectMobile viewportName={"mobile"} />
            <PeerConnectDesktop viewportName={"desktop"} />
        </div>
    );
}

export default PeerConnect;
