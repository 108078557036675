import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import OrgLink from "../OrgLink/OrgLink";
import ArrowSVG from "../SVGs/ArrowSVG";
import styles from "./StarterTile.module.css";

function StarterTile({
    Graphic = () => {},
    style,
    label,
    subtitle,
    href,
    src,
    viewportName,
    target,
    rel,
    ...props
}) {
    function TileComponent({ sendPageView, pageViewTitle, action, ...props }) {
        return href ? (
            <OrgLink
                {...props}
                sendPageView={sendPageView}
                pageViewTitle={pageViewTitle}
            />
        ) : (
            <button onClick={action} {...props} />
        );
    }
    return (
        <TileComponent
            target={target}
            rel={rel}
            style={style}
            className={[
                styles.starter_tile,
                styles[viewportName],
                src ? undefined : styles.white_tile,
            ].join(" ")}
            to={href}
            {...props}
        >
            <img className={styles.image} src={src} alt="" />
            <div className={styles.cover} />
            <div className={styles.graphic_container}>
                <Graphic className={styles.graphic} />
            </div>
            <div className={"mobile " + styles.text}>
                <strong className={styles.label} style={props.labelStyle}>
                    {label}
                </strong>
                <ArrowSVG className={"mobile " + styles.mobile_arrow} />
            </div>
            <div className={"desktop " + styles.text}>
                <strong className={styles.label} style={props.labelStyle}>
                    {label}
                </strong>
                <ArrowSubtitle
                    darkMode={!!src}
                    text={subtitle}
                    style={{
                        fontSize: 14,
                    }}
                />
            </div>
        </TileComponent>
    );
}

export default StarterTile;
