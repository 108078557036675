export const anonymousOptions = [
    {
        name: "Tutanota",
        src: "/images/global/emails/tutanota.png",
        href: "https://tuta.com/",
    },
    {
        name: "ProtonMail",
        src: "/images/global/emails/protonmail.png",
        href: "https://proton.me/",
    },
    {
        name: "Hushmail",
        src: "/images/global/emails/hushmail.png",
        href: "https://hushmail.com/",
    },
    {
        name: "Mailfence",
        src: "/images/global/emails/mailfence.png",
        href: "https://mailfence.com/",
    },
];
