import styles from "./ScrollToTopButton.module.css";
import ArrowSVG from "../SVGs/ArrowSVG";
import useScrollPosition from "../../hooks/useScrollPosition";

function ScrollToTopButton({ viewportName = "desktop", topThreshold = 0 }) {
    const scrollPosition = useScrollPosition();
    function handleClick() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <button
            onClick={handleClick}
            id={styles.button}
            aria-label="Scroll to top"
            className={scrollPosition <= topThreshold ? styles.hide : ""}
        >
            <ArrowSVG className={styles.arrow} />
        </button>
    );
}

export default ScrollToTopButton;
