import { useCallback, useEffect } from "react";
import { useOrgObject } from "../../hooks/useOrgObject";
import useActivateMatomo from "../../hooks/useActivateMatomo";

function OrgThemeHelper() {
    const orgObject = useOrgObject();
    useActivateMatomo(orgObject.region);

    const setSecondaryColor = useCallback(() => {
        document.documentElement.style.setProperty(
            "--secondary-color",
            orgObject.secondaryColor
        );
    }, [orgObject]);

    useEffect(() => {
        setSecondaryColor();
    }, [orgObject, setSecondaryColor]);

    return null;
}

export default OrgThemeHelper;
