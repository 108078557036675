export const headerLinks = [
    {
        name: "Home",
        href: "/",
    },

    {
        name: "Mental Fitness Check",
        href: `/mental-fitness-check`,
    },
    {
        name: "Explore Peer Supporters",
        href: "/explore-peer-supporters",
    },
    {
        name: "Anonymous Options",
        href: "/anonymous-options",
    },
    // {
    //     name: "Crisis Support",
    //     href: "/crisis-support",
    // },
];

export const footerLinks = {
    // "Need Help?": [
    //     // {
    //     //     name: "Security & Privacy",
    //     //     href: "/building-trust",
    //     // },
    //     { name: "Crisis Support", href: "/crisis-support" },
    //     // { name: "Anonymous Options", href: "/anonymous-options" },
    // ],
    Legal: [
        {
            name: "Privacy Policy",
            href: "https://www.keelmind.com/privacy-policy",
            target: "_blank",
        },
        {
            name: "Terms of Use",
            href: "https://www.keelmind.com/terms-of-use",
            target: "_blank",
        },
        // {
        //     name: "Terms & Conditions",
        //     href: "https://www.keel.digital/terms-and-conditions",
        // },
    ],
};
