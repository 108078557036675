import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";
import MFCiframe from "../../../components/MFCiframe/MFCiframe";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";
import styles from "./MentalFitnessCheckDesktop.module.css";

function MentalFitnessCheckDesktop(viewportName) {
    return (
        <main
            className={viewportName}
            style={{ paddingTop: viewportName === "mobile" && 25 }}
        >
            <ScrollToTopButton topThreshold={300} />
            <Header darkMode={true} viewportName={viewportName} />
            <section id={styles.content_section} className="cap-width">
                <div id={styles.content_section_content}>
                    <div className={styles.heading_container}>
                        <div>
                            <h1>Complete Your Mental Fitness Check</h1>
                        </div>
                        <p>
                            Get insights into the most relevant mental wellness
                            indicators: mental strain, burnout, stress,
                            distress, tiredness and confidence.
                        </p>
                    </div>
                    <div className={styles.iframe_container}>
                        <MFCiframe />
                    </div>
                </div>
            </section>
            <Footer viewportName={viewportName} />
        </main>
    );
}

export default MentalFitnessCheckDesktop;
