import styles from "./MFCiframe.module.css";

function MFCiframe() {
    const iframeSrc =
        window.location.hostname === "localhost" ||
        window.location.hostname === "dev.peersupport.keelmind.com"
            ? "https://assets.cdn.geta-head.com/kmta/dev/index.html"
            : "https://assets.cdn.geta-head.com/kmta/prod/index.html";

    return (
        <iframe
            id={styles.iframe}
            title="Thymia Mental Fitness Check"
            src={iframeSrc}
            frameborder="0"
            allow="microphone"
        ></iframe>
    );
}

export default MFCiframe;
