import styles from "./MemberModal.module.css";
import OrgLink from "../OrgLink/OrgLink";

function MemberModal({ member, setMember }) {
    return (
        <div
            id={styles.modal_background}
            className={Object.keys(member).length > 0 ? "" : styles.hide}
        >
            <div className="mobile-cap">
                <div id={styles.member_modal} className="hide-scrollbar">
                    <button
                        onClick={() => setMember({})}
                        id={styles.close_button}
                        aria-label="Close details modal"
                    >
                        <img src="/images/global/modal-close-icon.svg" alt="" />
                    </button>
                    <img
                        src={member.headshotSrc}
                        alt={(member?.name || "") + " headshot"}
                        id={styles.headshot}
                    />
                    <div id={styles.text}>
                        <strong id={styles.name}>{member.name}</strong>
                        <div className={styles.light_text}>
                            {member.subtitle}
                        </div>
                    </div>
                    <div id={styles.bottom}>
                        <p>{member.bio}</p>
                        <div id={styles.controls}>
                            {/* <button
                                id={styles.listen_button}
                                className="link-button"
                            >
                                Listen to Story
                            </button> */}
                            <OrgLink
                                id={styles.connect_link}
                                className="link-button"
                                to="/sign-in"
                            >
                                Listen to Story
                            </OrgLink>
                            <OrgLink
                                id={styles.connect_link}
                                className="link-button outline dark-mode"
                                to="/sign-in"
                            >
                                Connect
                            </OrgLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberModal;
