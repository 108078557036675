import HomeDesktop from "./HomeDesktop/HomeDesktop";
import HomeMobile from "./HomeMobile/HomeMobile";
import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import usePageTitle from "../../hooks/usePageTitle";
import { useOrgObject } from "../../hooks/useOrgObject";
import useTrackPageView from "../../hooks/useTrackPageView";

function Home() {
    const { name } = useOrgObject();
    usePageTitle(`Home | ${name} Peer Support`);
    useTrackPageView();

    return (
        <div>
            <OrgThemeHelper />
            <HomeDesktop viewportName={"desktop"} />
            <HomeMobile viewportName={"mobile"} />
        </div>
    );
}

export default Home;
