import { useParams } from "react-router-dom";
import styles from "../WellnessHubArticle.module.css";
import { useOrgObject } from "../../../hooks/useOrgObject";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";
import WellnessHubCarousel from "../../../components/WellnessHubCarousel/WellnessHubCarousel";
import usePageTitle from "../../../hooks/usePageTitle";
import useTrackPageView from "../../../hooks/useTrackPageView";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";

function WellnessHubArticleDesktop({ viewportName = "desktop" }) {
    const { id } = useParams();
    const { placeholderArticles, name } = useOrgObject();

    const { title, src, content } = placeholderArticles[id];

    usePageTitle(`Wellness Hub Article | ${name} Peer Support`);
    useTrackPageView();

    return (
        <main
            id={styles.main}
            className={viewportName}
            style={{ paddingTop: viewportName === "mobile" && 25 }}
        >
            <ScrollToTopButton topThreshold={300} />
            <Header viewportName={viewportName} darkMode={true} />
            <section id={styles.content_section}>
                <div
                    className="section-content"
                    id={styles.content_section_content}
                >
                    <div id={styles.hero} className={styles[viewportName]}>
                        <img id={styles.hero_image} src={src} alt="" />
                        <div id={styles.hero_image_cover}></div>
                        <h1 id={styles.title}>{title}</h1>
                    </div>
                    <div
                        id={styles.content_container}
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                </div>
            </section>
            <section id={styles.wellness_carousel_section}>
                <div className="section-content">
                    <WellnessHubCarousel data={placeholderArticles} />
                </div>
            </section>
            <Footer />
        </main>
    );
}

export default WellnessHubArticleDesktop;
