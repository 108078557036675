export function readinessCheckStarterTile(orgObject, viewportName) {
    return {
        src: "/images/" + orgObject.type + "/starter-tile-1.jpg",
        label: "Mental Fitness Check",
        subtitle: "Quick & Confidential",
        href: `/mental-fitness-check`,
        viewportName,
        labelStyle: {
            // writingMode: "vertical-lr",
            // maxWidth: '50%'
        },
    };
}

export function connectWithPeerSupporterStarterTile(orgObject, viewportName) {
    return {
        src: "/images/" + orgObject.type + "/starter-tile-2.jpg",
        label: "Explore Peer Supporters",
        subtitle: "You're not alone",
        href: "/explore-peer-supporters",
        viewportName,
    };
}

export function wellnessHubStarterTile(orgObject, viewportName, action) {
    // if action is passed (on desktop), do action. otherwise, use href (on mobile)

    return {
        src: "/images/" + orgObject.type + "/starter-tile-3.jpg",
        label: "Wellness Hub",
        subtitle: "Articles & Resources",
        href: action === undefined ? "/wellness-hub/0" : "",
        action,
        viewportName,
    };
}

export function additionalResourcesStarterTile(orgObject, viewportName) {
    return {
        src: "/images/" + orgObject.type + "/starter-tile-4.jpg",
        label: "Additional Resources",
        subtitle: "Helpful Links & Resources",
        href: orgObject.resourcesUrl,
        viewportName,
        target: "_blank",
        rel: "noreferrer",
    };
}
