import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import useDynamicViewportName from "../../hooks/useDynamicViewportName";
import MentalFitnessCheckDesktop from "./MentalFitnessCheckDesktop/MentalFitnessCheckDesktop";

function MentalFitnessCheck() {
    const viewportName = useDynamicViewportName();
    return (
        <div>
            <OrgThemeHelper />
            <MentalFitnessCheckDesktop viewportName={viewportName} />
        </div>
    );
}

export default MentalFitnessCheck;
