import { nycorrectionsPlaceholderArticles } from "./defaultData";

const orgData = {
    keelmind: {
        name: "Keel Mind",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/keelmind/img/keelmind-logo.png",
        secondaryColor: "#FF5E1A",
        region: "us",
        type: "generic",
        resourcesUrl: "https://www.keelmind.com/",
        // removePillars: {
        //     mentalFitnessCheck: true,
        //     wellnessHub: true,
        //     keelReels: true,
        // },
    },
    monmouth: {
        name: "Monmouth County",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/monmouth/img/monmouth-logo.png",
        secondaryColor: "#DDA04B",
        region: "us",
        type: "police",
        orgCodes: {
            "Peer Member": "x7QX",
            "Peer Supporter": "QpM1",
        },
        resourcesUrl: "https://www.accessqhs.com/",
    },
    nycorrections: {
        name: "NYC Department of Correction",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/nycorrections/img/nycorrections-logo.png",
        secondaryColor: "#D55436",
        region: "us",
        type: "specific/nycorrections",
        placeholderArticles: nycorrectionsPlaceholderArticles,
        resourcesUrl:
            "https://www.nyc.gov/site/jointheboldest/overview/correction-assistance-response-for-employees.page",
        // starterTiles: {
        //     3: (orgObject, viewportName) => {
        //         return {
        //             src: "/images/" + orgObject.type + "/starter-tile-4.jpg",
        //             label: "CARE Portal",
        //             subtitle: "Join the Boldest",
        //             target: "_blank",
        //             href: "https://www.nyc.gov/site/jointheboldest/overview/correction-assistance-response-for-employees.page",
        //             viewportName,
        //         };
        //     },
        // },
        orgCodes: {
            "Peer Member": "Eqxl",
            "Peer Supporter": "iekH",
        },
    },
    yorkcism: {
        name: "York CISM",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/yorkcism/img/yorkcism-logo.png",
        secondaryColor: "#D31145",
        type: "police",
        region: "ca",
        reels: [
            {
                name: "Ethan Collins",
                subtitle: "First Responder",
                headshotSrc: "/images/generic/Ethan-Collins-headshot.jpg",
                bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent rutrum consectetur elit, eget pharetra nulla vulputate vel. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.         Nam quis nisl sed nulla elementum finibus et quis arcu. Donec orci augue, euismod ut feugiat nec, cursus in sem.",
                audioSrc: "/audio/generic/Ahad-Bandealy-interview.m4a",
            },
            {
                name: "Sophia Harris",
                subtitle: "First Responder",
                headshotSrc: "/images/generic/Sophia-Harris-headshot.jpg",
                bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent rutrum consectetur elit, eget pharetra nulla vulputate vel. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.         Nam quis nisl sed nulla elementum finibus et quis arcu. Donec orci augue, euismod ut feugiat nec, cursus in sem.",
                audioSrc: "/audio/generic/Liz-Cordeiro-interview.m4a",
            },
        ],
        orgCodes: {
            "Peer Member": "MXPp",
            "Peer Supporter": "VG41",
        },
        resourcesUrl: "https://yorkcism.ca/",
    },
    firstnet: {
        name: "AT&T Firstnet",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/firstnet/img/firstnet-logo.png",
        type: "specific/firstnet",
        region: "us",
        secondaryColor: "#00c7ff",
        orgCodes: {
            "Peer Member": "mcjn",
            "Peer Supporter": "brwf",
        },
        resourcesUrl: "https://www.firstnet.com/",
    },
    ncdoc: {
        name: "North Carolina Department of Adult Correction",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/ncdoc/img/ncdoc-logo.png",
        type: "police",
        // secondaryColor: "#d81921",
        secondaryColor: "#d81921",
        region: "us",
        orgCodes: {
            "Peer Supporter": "nEsy",
            "Peer Member": "6m5y",
        },
        resourcesUrl: "https://www.dac.nc.gov/information-and-services",
    },
    botg: {
        name: "Boots on the Ground",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/botg/img/botg-logo.png",
        type: "generic",
        region: "ca",
        secondaryColor: "#5DC6E8",
        orgCodes: {
            "Peer Member": "Ykjp",
            "Peer Supporter": "sE7k",
        },
        resourcesUrl: "https://www.bootsontheground.ca/",
    },
    peelregional: {
        name: "Peel Regional Police",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/peelregional/img/peelregional-logo.png",
        type: "police",
        region: "ca",
        secondaryColor: "#0072B8",
        orgCodes: {
            "Peer Member": "qlYl",
            "Peer Supporter": "VUKx",
        },
        resourcesUrl: "https://www.peelpolice.ca/en/index.aspx",
    },
    edmontonps: {
        name: "Edmonton Police Service",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/edmontonps/img/edmontonps-logo.png",
        type: "police",
        region: "ca",
        secondaryColor: "#E3AB53",
        resourcesUrl: "https://www.edmontonpolice.ca/",
    },
    wiledr: {
        name: "LEDR",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/wiledr/img/wiledr-logo.png",
        type: "specific/wiledr",
        region: "us",
        secondaryColor: "#E3AB53",
        // starterTiles: {
        //     3: (orgObject, viewportName) => {
        //         return {
        //             src: "/images/" + orgObject.type + "/starter-tile-4.jpg",
        //             label: "Executive Log In",
        //             subtitle: "LEDR Executive Portal",
        //             target: "_blank",
        //             href: orgObject.loginLink,
        //             viewportName,
        //         };
        //     },
        // },
        resourcesUrl: "https://www.wiledr.org/",
    },
    wkpd: {
        name: "Waukesha Police Department",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/wkpd/img/wkpd-logo.png",
        type: "police",
        region: "us",
        secondaryColor: "#E31C3D",
        orgCodes: {
            "Peer Member": "imj2",
            "Peer Supporter": "5QEQ",
        },
        resourcesUrl:
            "https://www.waukesha-wi.gov/government/departments/police-department.php",
    },
    waynecoso: {
        name: "Wayne County Sheriff's Office",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/waynecoso/img/waynecoso-logo.png",
        type: "police",
        region: "us",
        secondaryColor: "#F9A301",
        orgCodes: {
            "Peer Member": "RKtm",
            "Peer Supporter": "7k2c",
        },
        resourcesUrl: "https://www.sheriffconnect.com/",
    },
    fst5: {
        name: "Frontline Strong Together",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/fst5/img/fst5-logo.png",
        type: "generic",
        region: "us",
        secondaryColor: "#d9ae2b",
        orgCodes: {
            "Peer Member": "HjTY",
            "Peer Supporter": "Mvw0",
        },
        resourcesUrl: "https://fst5.org/",
    },
    marquette: {
        name: "Marquette Police Department",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/marquette/img/marquette-logo.png",
        type: "police",
        region: "us",
        secondaryColor: "#CA0A05",
        orgCodes: {
            "Peer Member": "KmqE",
            "Peer Supporter": "RL2F",
        },
        resourcesUrl: "https://www.marquettemi.gov/departments/police/",
    },
    scemsa: {
        name: "South Carolina EMS Association",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/scemsa/img/scemsa-logo.png",
        type: "ems",
        region: "us",
        secondaryColor: "#5a8be4",
        resourcesUrl: "https://scemsa.org/",
    },
    nypdwellness: {
        name: "NYPD Health & Wellness",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/nypdwellness/img/nypdwellness-logo.png",
        type: "specific/nypdwellness",
        region: "us",
        secondaryColor: "#f1a637",
        resourcesUrl: "https://nypdhws.squarespace.com/",
    },
    lasd: {
        name: "LA County Sheriff's Department",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/lasd/img/lasd-logo.png",
        type: "police",
        region: "us",
        secondaryColor: "#d0aa60",
        resourcesUrl: "https://lasd.org/",
    },
    adps: {
        name: "Arkansas Department of Public Safety",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/adps/img/adps-logo.png",
        type: "police",
        region: "us",
        secondaryColor: "#ba1424",
        resourcesUrl: "https://dps.arkansas.gov/",
    },
    miamipd: {
        name: "Miami Police Department",
        type: "police",
        region: "us",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/miamipd/img/miamipd-logo.png",
        secondaryColor: "#ea920c",
        resourcesUrl:
            "https://www.miami.gov/My-Government/Departments/Police-Department",
    },
    lacofd: {
        name: "Los Angeles County Fire Department",
        type: "fire",
        region: "us",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/lacofd/img/lacofd-logo.png",
        secondaryColor: "#ee3944",
        resourcesUrl: "https://fire.lacounty.gov/",
    },
    msp: {
        name: "Michigan State Police",
        type: "police",
        region: "us",
        logoSrc:
            "https://assets.cdn.geta-head.com/peersupport-v2/msp/img/msp-logo.png",
        secondaryColor: "#dcb32d",
        resourcesUrl: "https://www.michigan.gov/msp",
    },
};

export default orgData;
