import { useEffect } from "react";

function useTrackPageView() {
    function sendMatomo() {
        if (
            window._paq &&
            (window.location.hostname === "localhost" ||
                window.location.hostname === "dev.peersupport.keelmind.com")
        ) {
            window._paq.push(["setCustomUrl", window.location.pathname]);
            window._paq.push(["setDocumentTitle", document.title]);
            window._paq.push(["trackPageView"]);
        }
    }

    function sendGA() {
        if (window.gtag) {
            window.gtag("event", "page_view", {
                page_path: window.location.pathname,
                page_location: window.location.href,
                page_title: document.title,
            });
        }
    }

    useEffect(() => {
        sendMatomo();
        sendGA();
    }, []);

    return null;
}

export default useTrackPageView;
