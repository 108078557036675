import styles from "./Hamburger.module.css";
import OrgLink from "../OrgLink/OrgLink";
import { useOrgObject } from "../../hooks/useOrgObject";
import { headerLinks } from "../../data/navigation";
import { useContext } from "react";
import { SignUpModalContext } from "../SignUpModalContextProvider/SignUpModalContextProvider";

function HamburgerMenu(props) {
    const orgObject = useOrgObject();
    const { setMenuState, menuState } = useContext(SignUpModalContext);
    function handleCloseClick() {
        // props.menuRef.current.classList.toggle("show");
        setMenuState(false);
    }

    function handleEscape(event) {
        if (event.keyCode === 27) {
            setMenuState(false);
        }
    }

    return (
        <div
            // style={{ display: "none" }}
            className={[
                styles.hamburger_menu_wrapper,
                menuState ? styles.show : "",
                props.className,
            ].join(" ")}
            ref={props.menuRef}
            onKeyDown={handleEscape}
        >
            <div className={styles.cover} onClick={handleCloseClick}></div>
            <div className={styles.menu_scroll_wrapper}>
                <div className={styles.menu}>
                    <div className={styles.menu_header}>
                        <OrgLink to="/">
                            <img
                                draggable={false}
                                id={styles.logo}
                                src={orgObject.logoSrc}
                                alt={orgObject.name + " logo"}
                            />
                        </OrgLink>
                        <button
                            onClick={handleCloseClick}
                            className={[styles.close_button, "mobile"].join(
                                " "
                            )}
                            aria-label="Close navigation menu"
                        >
                            <img
                                src="/images/global/modal-close-icon-thick-white.svg"
                                alt="Close navigation menu"
                            />
                        </button>
                    </div>
                    {headerLinks
                        .filter(({ name }) => {
                            if (
                                orgObject.removePillars.keelReels &&
                                name === "Explore Peer Supporters"
                            ) {
                                return false;
                            } else if (
                                orgObject.removePillars.mentalFitnessCheck &&
                                name === "Mental Fitness Check"
                            ) {
                                return false;
                            }
                            return true;
                        })
                        .map(({ name, href, ...props }) => {
                            return (
                                <OrgLink
                                    key={name}
                                    className={styles.link}
                                    to={href}
                                    {...props}
                                >
                                    {name}
                                </OrgLink>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default HamburgerMenu;
