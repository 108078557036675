import OrgLink from "../OrgLink/OrgLink";
import { footerLinks } from "../../data/navigation";
import styles from "./Footer.module.css";
import { useOrgObject } from "../../hooks/useOrgObject";

function Footer({ viewportName }) {
    const { region } = useOrgObject();
    return (
        <footer id={styles.footer} className={styles[viewportName]}>
            <div id={styles.top}>
                <div id={styles.left}>
                    <strong className="desktop">
                        Peer Support Program Powered By:
                    </strong>
                    <div className={["mobile", styles.light_heading].join(" ")}>
                        Peer Support Program Powered By:
                    </div>
                    <div
                        id={styles.logos_container}
                        className={
                            viewportName === "mobile"
                                ? styles.smaller_logos
                                : ""
                        }
                    >
                        <img
                            src="/images/global/keel-mind-blue.png"
                            alt="Keel Mind Logo"
                        />
                        <img
                            src="/images/global/challenge-coin.png"
                            alt="Keel Mind challenge coin"
                        />
                        {region === "us" && (
                            <img
                                src="/images/global/us-footer-logo.jpg"
                                alt="monochromatic United States flag"
                            />
                        )}
                        {region === "ca" && (
                            <img
                                src="/images/global/canada-footer-logos.png"
                                alt="Logos for Government of Canada, Employment Ontario, and Government of Ontario"
                            />
                        )}
                    </div>
                    <div id={styles.legal_container} className="mobile">
                        {footerLinks["Legal"].map(
                            ({ name, href, ...props }) => (
                                <OrgLink
                                    key={name}
                                    className={styles.link}
                                    to={href}
                                    {...props}
                                >
                                    {name}
                                </OrgLink>
                            )
                        )}
                    </div>
                    <div id={styles.copyright_mobile} className="mobile">
                        © Copyright 2025 | All Rights Reserved |{" "}
                        <OrgLink
                            className={styles.link}
                            to={"https://keel.digital/"}
                        >
                            Keel.digital
                        </OrgLink>
                    </div>
                </div>
                <div id={styles.right} className="desktop">
                    {Object.keys(footerLinks).map((k) => (
                        <div key={k} className={styles.links_column}>
                            <strong id={styles.links_column_heading}>
                                {k}
                            </strong>
                            {footerLinks[k].map(({ name, href, ...props }) => (
                                <OrgLink
                                    key={name}
                                    className={styles.link}
                                    to={href}
                                    {...props}
                                >
                                    {name}
                                </OrgLink>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div id={styles.bottom} className="desktop">
                <div id={styles.line}></div>
                <div id={styles.copyright}>
                    © Copyright 2025 | All Rights Reserved |{" "}
                    <OrgLink
                        className={styles.link}
                        to={"https://keel.digital/"}
                    >
                        Keel.digital
                    </OrgLink>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
