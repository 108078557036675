import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SignUpModalContext } from "../SignUpModalContextProvider/SignUpModalContextProvider";

function LocationChangeHelper() {
    const location = useLocation();
    const { setMenuState } = useContext(SignUpModalContext);
    useEffect(() => {
        window.scrollTo(0, 0);
        setMenuState(false);
    }, [location, setMenuState]);

    return null;
}

export default LocationChangeHelper;
