import FloatingTray from "../../../components/FloatingTray/FloatingTray";
import StarterTile from "../../../components/StarterTile/StarterTile";
import styles from "./HomeMobile.module.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { useOrgObject } from "../../../hooks/useOrgObject";
import {
    connectWithPeerSupporterStarterTile,
    additionalResourcesStarterTile,
    readinessCheckStarterTile,
    wellnessHubStarterTile,
} from "../../../data/starterTiles";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";

function HomeMobile({ viewportName = "mobile" }) {
    const orgObject = useOrgObject();

    const mobileStarterTiles = [
        (orgObject.starterTiles?.[0] || readinessCheckStarterTile)(
            orgObject,
            viewportName
        ),
        (orgObject.starterTiles?.[1] || connectWithPeerSupporterStarterTile)(
            orgObject,
            viewportName
        ),
        (orgObject.starterTiles?.[2] || wellnessHubStarterTile)(
            orgObject,
            viewportName
        ),
        (orgObject.starterTiles?.[3] || additionalResourcesStarterTile)(
            orgObject,
            viewportName
        ),
        orgObject.starterTiles?.[4]?.(orgObject, viewportName),
        orgObject.starterTiles?.[5]?.(orgObject, viewportName),
    ];

    return (
        <main className={viewportName}>
            <ScrollToTopButton topThreshold={300} />
            <section id={styles.index_section}>
                <div id={styles.index_container}>
                    <div id={styles.index_background}></div>
                    <div id={styles.header_container}>
                        <Header viewportName={viewportName} />
                    </div>
                    <h1 id={styles.heading}>
                        Supporting Your Well-Being <span>Every Step</span> of
                        the Way.
                    </h1>
                    <FloatingTray offset={80}>
                        <div
                            id={styles.starter_tiles_grid}
                            // className={
                            //     mobileStarterTiles.filter(
                            //         (x) => x !== undefined
                            //     ).length > 4
                            //         ? styles.six_tiles
                            //         : undefined
                            // }
                        >
                            {orgObject.removePillars
                                .mentalFitnessCheck ? null : (
                                <StarterTile {...mobileStarterTiles[0]} />
                            )}
                            {orgObject.removePillars.keelReels ? null : (
                                <StarterTile {...mobileStarterTiles[1]} />
                            )}
                            {orgObject.removePillars.wellnessHub ? null : (
                                <StarterTile {...mobileStarterTiles[2]} />
                            )}
                            <StarterTile {...mobileStarterTiles[3]} />
                            {/* {mobileStarterTiles[4] && (
                                <StarterTile {...mobileStarterTiles[4]} />
                            )}
                            {mobileStarterTiles[5] && (
                                <StarterTile {...mobileStarterTiles[5]} />
                            )} */}
                        </div>
                    </FloatingTray>
                </div>
                {/* <div id={styles.bottom_link_button} className="mobile-cap">
                    <OrgLink
                        className="link-button"
                        id={styles.sign_in_link}
                        to={"/sign-in"}
                    >
                        <ArrowSVG style={{ stroke: "white" }} /> Sign in
                    </OrgLink>
                </div> */}
            </section>
            <Footer viewportName={viewportName} />
        </main>
    );
}

export default HomeMobile;
