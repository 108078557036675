import { useContext } from "react";
import styles from "./Hamburger.module.css";
import { SignUpModalContext } from "../SignUpModalContextProvider/SignUpModalContextProvider";
function HamburgerButton(props) {
    const { setMenuState } = useContext(SignUpModalContext);
    function handleClick() {
        if (!props.ghost) {
            setMenuState((prev) => !prev);
        }
    }

    return (
        <button
            onClick={handleClick}
            className={[
                styles.hamburger_button,
                props.dark ? styles.dark : "",
                props.className,
            ].join(" ")}
            aria-label="Open navigation menu"
        >
            <div className={styles.line}></div>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
        </button>
    );
}

export default HamburgerButton;
