import ArrowSVG from "../SVGs/ArrowSVG";
import styles from "./ArrowSubtitle.module.css";

function ArrowSubtitle({ style, text, darkMode, arrowClassName, className }) {
    return (
        <div
            style={style}
            className={[styles.arrow_subtitle, className].join(" ")}
        >
            <ArrowSVG className={arrowClassName} />{" "}
            <div
                className={styles.text}
                style={{ color: darkMode ? "white" : "" }}
            >
                {text}
            </div>
        </div>
    );
}

export default ArrowSubtitle;
