import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import OrgLink from "../OrgLink/OrgLink";
import styles from "./ToolCard.module.css";

function ToolCard({
    Graphic = () => {},
    heading,
    linkText,
    href,
    action,
    children,
    target,
    rel,
    ...props
}) {
    return (
        <div className={styles.tool_card}>
            <Graphic />
            <div className={styles.bottom}>
                <h3 className={styles.heading}>{heading}</h3>
                <p className={styles.text}>{children}</p>
                {linkText &&
                    ((href && (
                        <OrgLink
                            target={"_blank"}
                            rel={"noreferrer"}
                            to={href}
                            className={styles.link}
                            {...props}
                        >
                            <ArrowSubtitle text={linkText} />
                        </OrgLink>
                    )) ||
                        (action && (
                            <button onClick={action} className={styles.link}>
                                <ArrowSubtitle text={linkText} />
                            </button>
                        )))}
            </div>
        </div>
    );
}

export default ToolCard;
