import ArrowSubtitle from "../../../components/ArrowSubtitle/ArrowSubtitle";
import EmailOption from "../../../components/EmailOption/EmailOption";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";
import { anonymousOptions } from "../../../data/anonymousOptions";
import { useOrgObject } from "../../../hooks/useOrgObject";
import usePageTitle from "../../../hooks/usePageTitle";
import styles from "./AnonymousOptionsDesktop.module.css";

function AnonymousOptionsDesktop({ viewportName = "desktop" }) {
    const { name } = useOrgObject();
    usePageTitle(`Home | ${name} Peer Support`);

    return (
        <main
            className={viewportName}
            style={{ paddingTop: viewportName === "mobile" && 25 }}
        >
            <ScrollToTopButton topThreshold={300} />
            <Header darkMode={true} viewportName={viewportName} />
            <section id={styles.content_section}>
                <div id={styles.content_section_content} className="cap-width">
                    <div className={styles.heading_container}>
                        <div>
                            <ArrowSubtitle
                                text={"Anonymous Options"}
                                style={{
                                    justifyContent: "center",
                                }}
                            />
                            <h1>Don't want to use your personal email?</h1>
                        </div>
                        <p>
                            We are committed to respecting your privacy. Here
                            are some recommended options for creating an
                            anonymous email address to sign up without using
                            your personal email.
                        </p>
                        <strong>
                            Once you've set one up, come back and use that
                            address.
                        </strong>
                    </div>
                    <div className={styles.options_container}>
                        {anonymousOptions.map((o) => (
                            <EmailOption {...o} key={o.name} />
                        ))}
                    </div>
                </div>
            </section>
            <Footer viewportName={viewportName} />
        </main>
    );
}

export default AnonymousOptionsDesktop;
