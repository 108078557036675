import { useParams } from "react-router-dom";
import orgs from "../data/orgs";
import { defaultPlaceholderArticles, defaultReels } from "../data/defaultData";

export function useOrgObject() {
    const { org } = useParams();

    const output = orgs?.[org] || orgs["keelmind"];

    const key = orgs?.[org] ? org : "";

    const placeholderArticles =
        output.placeholderArticles || defaultPlaceholderArticles;

    const removePillars = output.removePillars || {
        mentalFitnessCheck: false,
        wellnessHub: false,
        keelReels: false,
    };

    const reels = output.reels || defaultReels;

    const loginLink = `https://app.${output.region}.geta-head.com/app/gah/`;

    // actual org object overrides all duplicate properties
    // assignments here are intended for default values
    return {
        key,
        placeholderArticles,
        removePillars,
        reels,
        loginLink,
        ...output,
    };
}
