import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import "./fonts.css";
import Home from "./pages/Home/Home";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import SignIn from "./pages/SignIn/SignIn";
import PeerConnect from "./pages/PeerConnect/PeerConnect";
import LocationChangeHelper from "./components/LocationChangeHelper/LocationChangeHelper";
import SignUpModalContextProvider from "./components/SignUpModalContextProvider/SignUpModalContextProvider";
import WellnessHubArticle from "./pages/WellnessHubArticle/WellnessHubArticle";
// import CrisisSupport from "./pages/CrisisSupport/CrisisSupport";
import AllOrgs from "./pages/AllOrgs/AllOrgs";
import AnonymousOptions from "./pages/AnonymousOptions/AnonymousOptions";
import MentalFitnessCheck from "./pages/MentalFitnessCheck/MentalFitnessCheck";
const root = ReactDOM.createRoot(document.getElementById("root"));

function Root() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <SignUpModalContextProvider>
                    <LocationChangeHelper />
                    <Routes>
                        {/* unbranded routes */}
                        <Route path="*" element={<Home />} />
                        {/* <Route path="/sign-in" element={<SignIn />} /> */}
                        <Route
                            path="/explore-peer-supporters"
                            element={<PeerConnect />}
                        />
                        <Route
                            path="/wellness-hub/:id"
                            element={<WellnessHubArticle />}
                        />
                        {/* <Route path="/crisis-support" element={<CrisisSupport />} /> */}
                        <Route
                            path="/anonymous-options"
                            element={<AnonymousOptions />}
                        />
                        <Route
                            path="/mental-fitness-check"
                            element={<MentalFitnessCheck />}
                        />

                        <Route path="/all-orgs" element={<AllOrgs />} />

                        {/* org routes  */}
                        <Route path=":org/*" element={<Home />} />
                        {/* <Route path=":org/sign-in" element={<SignIn />} /> */}
                        <Route
                            path=":org/explore-peer-supporters"
                            element={<PeerConnect />}
                        />
                        <Route
                            path=":org/wellness-hub/:id"
                            element={<WellnessHubArticle />}
                        />
                        <Route
                            path=":org/anonymous-options"
                            element={<AnonymousOptions />}
                        />
                        <Route
                            path=":org/mental-fitness-check"
                            element={<MentalFitnessCheck />}
                        />
                        {/* <Route
                        path=":org/crisis-support"
                        element={<CrisisSupport />}
                    /> */}
                    </Routes>
                </SignUpModalContextProvider>
            </BrowserRouter>
        </React.StrictMode>
    );
}

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
