import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import useDynamicViewportName from "../../hooks/useDynamicViewportName";
import { useOrgObject } from "../../hooks/useOrgObject";
import Home from "../Home/Home";
import WellnessHubArticleDesktop from "./WellnessHubArticleDesktop/WellnessHubArticleDesktop";

function WellnessHubArticle() {
    const { removePillars } = useOrgObject();
    const viewportName = useDynamicViewportName();

    return removePillars.wellnessHub ? (
        <Home />
    ) : (
        <div>
            <OrgThemeHelper />
            <WellnessHubArticleDesktop viewportName={viewportName} />
        </div>
    );
}

export default WellnessHubArticle;
