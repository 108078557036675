import styles from "./GraphicOverlay.module.css";

function GraphicOverlay({
    className,
    removeDefaultStyle = false,
    id,
    top,
    left,
    bottom,
    right,
    children,
    style,
}) {
    return (
        <div
            id={id}
            className={[
                removeDefaultStyle ? "" : styles.default,
                styles.overlay,
                className,
            ].join(" ")}
            style={{ top, left, bottom, right, ...style }}
        >
            {children}
        </div>
    );
}

export default GraphicOverlay;
