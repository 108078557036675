import ArrowSubtitle from "../../../components/ArrowSubtitle/ArrowSubtitle";
import Header from "../../../components/Header/Header";
import ReelsList from "../../../components/ReelsList/ReelsList";
import styles from "./PeerConnectDesktop.module.css";
import WellnessHubCarousel from "../../../components/WellnessHubCarousel/WellnessHubCarousel";
import Footer from "../../../components/Footer/Footer";
// import ProgramBanner from "../../../components/ProgramBanner/ProgramBanner";
// import OrgLink from "../../../components/OrgLink/OrgLink";
import { useOrgObject } from "../../../hooks/useOrgObject";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";

function PeerConnectDesktop({ viewportName = "desktop" }) {
    const { reels, placeholderArticles, removePillars } = useOrgObject();

    return (
        <main className={viewportName}>
            <ScrollToTopButton topThreshold={300} />
            <Header darkMode={true} viewportName={viewportName} />
            <section id={styles.reels_section}>
                <div
                    className="section-content"
                    id={styles.reels_section_content}
                >
                    <div id={styles.connections_heading}>
                        <ArrowSubtitle text={"Shared Experiences"} />
                        <div id={styles.connections_heading_row}>
                            <h1>
                                Building strong connections with your members.
                            </h1>
                            <p>
                                Listening to the stories of your peers builds
                                stronger connections with peers that you share
                                similar experiences with.
                            </p>
                        </div>
                    </div>
                    <ReelsList data={reels} />

                    {/* <button
                        id={styles.browse_link}
                        className="link-button section-content"
                        onClick={handleConnectLink}
                    >
                        Connect to a Peer Supporter
                    </button> */}
                    {removePillars.wellnessHub ? null : (
                        <WellnessHubCarousel data={placeholderArticles} />
                    )}
                </div>
            </section>
            {/* <ProgramBanner /> */}
            <Footer viewportName={viewportName} />
        </main>
    );
}

export default PeerConnectDesktop;
