import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import WellnessArticleLink from "../WellnessArticleLink/WellnessArticleLink";
import styles from "./WellnessHubCarousel.module.css";
import { useEffect, useRef, useState } from "react";

export default function WellnessHubCarousel({ className, data, arrowText }) {
    const articleScrollContainerRef = useRef(null);
    const leftHoverArrowRef = useRef(null);
    const rightHoverArrowRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);
    const [hoverScrollDir, setHoverScrollDir] = useState(0);

    function hideArrows() {
        // hide left arrow
        if (articleScrollContainerRef.current.scrollLeft <= 10) {
            leftHoverArrowRef.current.parentElement.style.visibility = "hidden";
        } else {
            leftHoverArrowRef.current.parentElement.style.visibility =
                "visible";
        }

        // hide right arrow
        if (
            articleScrollContainerRef.current.scrollLeft >=
            articleScrollContainerRef.current.scrollWidth -
                articleScrollContainerRef.current.clientWidth -
                10
        ) {
            rightHoverArrowRef.current.parentElement.style.visibility =
                "hidden";
        } else {
            rightHoverArrowRef.current.parentElement.style.visibility =
                "visible";
        }
    }
    useEffect(() => {
        let interval;
        if (isHovering) {
            interval = setInterval(() => {
                articleScrollContainerRef.current.scrollBy({
                    left: hoverScrollDir * 50,
                    behavior: "smooth",
                });
            }, 75);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isHovering, hoverScrollDir]);

    return (
        <div
            className={["section-content", className].join(" ")}
            id={styles.wellness_section_content}
        >
            <div
                className={styles.center_heading}
                id={styles.wellness_hub_heading}
            >
                {arrowText && <ArrowSubtitle text={arrowText} />}
                <h2>
                    Wellness Content Tailored for our First Responder Members
                </h2>
            </div>
            <div id={styles.wellness_hub_articles_overflow_container}>
                <div
                    id={styles.wellness_hub_articles_container}
                    className="hide-scrollbar"
                    ref={articleScrollContainerRef}
                    onScroll={hideArrows}
                >
                    {Object.entries(data).map(
                        ([id, { title, href, src }], index) => (
                            <WellnessArticleLink
                                key={index}
                                id={id}
                                title={title}
                                href={href}
                                src={src}
                            />
                        )
                    )}
                </div>
                <div
                    id={styles.scroll_cover_right}
                    onMouseEnter={() => {
                        setHoverScrollDir(1);
                        setIsHovering(true);
                    }}
                    onMouseLeave={() => {
                        setHoverScrollDir(0);
                        setIsHovering(false);
                    }}
                    className={"desktop"}
                >
                    <img
                        ref={rightHoverArrowRef}
                        id={styles.right_hover_arrow}
                        className={styles.hover_arrow}
                        src="/images/global/right-arrow-blue.svg"
                        alt=""
                    />
                </div>
                <div
                    id={styles.scroll_cover_left}
                    onMouseEnter={() => {
                        setHoverScrollDir(-1);
                        setIsHovering(true);
                    }}
                    onMouseLeave={() => {
                        setHoverScrollDir(0);
                        setIsHovering(false);
                    }}
                    style={{ visibility: "hidden" }}
                    className={"desktop"}
                >
                    <img
                        ref={leftHoverArrowRef}
                        id={styles.left_hover_arrow}
                        className={styles.hover_arrow}
                        src="/images/global/left-arrow-blue.svg"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
}
